/**
 * Custom Login Styles
 *
 * Include styles you wish to use on the WordPress login screen
 *
 * @package Carbon
 * @subpackage SCSS
 * @since 2.0.0
 */

body.login {
	background: #f8f8f8;

	h1 {

		& > a {
			display: block;
			// width: 100%;
			width: 320px;
			height: 124px;
			// padding-bottom: 25px;
			// border-radius: 3px;
			// box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-image: url('../images/logo.svg');
			background-size: contain;
			overflow: hidden;
			text-indent: -9999px;
		}
	}

	form {
		.input {}
	}

	label {}

	#nav,
	#backtoblog {

		& > a {}
	}
}
